import { useEffect, useRef } from 'react';

/**
 * Custom Effect Hook to retrieve previous value
 * @param {any} value
 * @returns {any}
 */
export default function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
